import React,{useEffect, useState, Component, useRef} from 'react';
import './styles.scss'
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {openDial,closeDial} from '../../../../Redux/action/setopen'
import { useDispatch, useSelector } from 'react-redux';
import {checkAd} from '../../../../Redux/action/setad'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import Data from '../../../../data/stories.json'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import ads from '../../../../ads.json'
import adsvid from '../../../../data/adsvid.json'
import { register } from 'swiper/element/bundle';
import Ad from '../Pictures/your_ad.png'
import { useNavigate,Link,useParams, useSearchParams } from 'react-router-dom';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props}/>;
});



function Story_Content(){
  const [openMenu, setMenu]=React.useState(true)
  let imgAds=[]
  let vidAds=[]
  
  let duration=15
  let TimeInterval
  let time=0
  let array=[]
  register();
  let down=false
  let up=false
  let move=false

  let storyWidth=0
  let storyPicWidth=0
  let scrollcount=0

  let storyScrollElement

   let swipe=false
 
  let newCoords=0;
  let div=document.createElement('div')
  div.className="BlackDiv"
  div.id="blackdiv"
  let urlParam=document.location.search;

 let storyFormat=20
 let video=document.createElement('video')

 video.className="VideoAd"
 video.id="videoad"
 video.autoplay=true
 video.muted=true
 video.playsInline=true
 const checkStoryAd=useSelector((state)=>state.check.value)
 let progress=0
 let pictAd=document.createElement('img')
 pictAd.className="StoryImage"
 pictAd.id="pictAd"
 let switchtoad=false
const VideoRef=useRef(null)
 let start=0
let end=0
let rotation=0
let switcher=false;
let translateWidth=(window.screen.width/2)+1
let observedvideo

let sliderCount


   Data.item.forEach(function(item,index){
    array.push(item)
   })


 
   const [searchParams] = useSearchParams();
   const adindex2 = searchParams.get('ad');
   const storyAd = searchParams.get('v');
   const storyAdtype = searchParams.get('t');
   const [videoLoad, setVideoLoad]=React.useState(false)
   const[stateStory, setOpenStory]=useState(true)
   const circle_ref=useRef(null)
   const open = useSelector((state)=>state.open.value)
   const picturendex =useSelector((state)=>state.open.index)
   const plattform = useSelector((state)=>state.plattform.value)
   const dispatch = useDispatch()

  const handleClose = () => {
    clearInterval(TimeInterval)
    dispatch(closeDial())
   //document.exitFullscreen()

    div.remove()
  };




    
   
      


   function TimeLineVideoAd(){
    
   
   /* 
    
    
      if(document.getElementById('videoad')!=null){
        clearInterval(TimeInterval)
      
       let Interval=setInterval(()=>{
          if(document.getElementById('videoad').readyState==4){
            document.getElementById('videoad').style.display="block"
            document.getElementById('image').style.pointerEvents="auto"
            clearInterval(Interval)
            duration=document.getElementById('videoad').duration
            
            document.getElementById('videoad').load()
            TimeLineStart()
            progress=0
            time=0
          }
       },1000)
      
      }
      
      */
   }



  if(open===true){
   
   
   
  
    if(document.getElementById('videoad')!=null){
      clearInterval(TimeInterval)
    
     let Interval=setInterval(()=>{
  

        if(document.getElementById('videoad').readyState>=1){
          
          
          
          document.getElementById('image').style.pointerEvents="auto"
          clearInterval(Interval)
          
          duration=document.getElementById('videoad').duration
         
          TimeLineStart()
          progress=0
          time=0
        }
     },1000)
    
    }
    if(storyFormat==15){
      progress=0
          time=0
          duration=15
      TimeLineStart()
      
    }
    
    

    
}
else{
  clearInterval(TimeInterval)
}


function pauseTimeLine(){
  clearInterval(TimeInterval)

}


   
const showLoader=()=>{
   
  circle_ref.current.style.display="flex"

}
  
const hideLoader=()=>{
 circle_ref.current.style.display="none"

  
 
}


function nextSlide(){
  
  const swiperEl = document.querySelector('swiper-container');
 
 

  if(swiperEl.swiper.activeIndex>=sliderCount.length-1){
    handleClose()
  }
  start=0
  time=0
  progress=0
 
 
    swiperEl.swiper.slideNext()
    swiperEl.swiper.slideChange()
    
    clearInterval(TimeInterval)
}

function prevSlide(){
  
  const swiperEl = document.querySelector('swiper-container');
 
 

  if(swiperEl.swiper.activeIndex>=sliderCount.length-1){
    handleClose()
  }
  start=0
  time=0
  progress=0
 
 
    swiperEl.swiper.slidePrev()
    swiperEl.swiper.slideChange()
    
    clearInterval(TimeInterval)
}


function TimeLineStart(){


     

  clearInterval(TimeInterval)
 
  
    
  TimeInterval=setInterval(()=>{
    
  
  if(time>duration){
    nextSlide()
    
  }

  
 
     time=time+(duration/100)// 0.15 (15 sec)
     progress++
     document.getElementById("timeremains").style.width=progress + "%"
     
   },duration*10) //150  (15 sec) 
  
  
}


const handleVideoAdPause=(e)=>{
  document.getElementById("storyvideoad").pause()
}


const handleVideoAdPlay=(e)=>{
  const swiperEl = document.querySelector('swiper-container');
  if(swiperEl.swiper.activeIndex===1){
  document.getElementById("storyvideoad").play()
}
  }


  const timeLineVideo=(e)=>{

    if(time>=duration){
      nextSlide()
      
    }

    duration=Math.round(e.target.duration)
    time=Math.round(e.target.currentTime)
document.getElementById("timeremains").style.width=(time*100)/duration + "%"



    /*
   
    videoAdTimeline2.style.width=(time*100)/videoDuration + "%"

    videoAd.addEventListener('timeupdate',adTimeUpdate)
     */

   // time=time+(duration/100)// 0.15 (15 sec)
    //progress++
    //document.getElementById("timeremains").style.width=progress + "%"
  }


async function checkElement(){
 
  await new Promise((resolve,reject)=>{
    if(document.getElementById("storyscreen")!=null){

      
      sliderCount = document.querySelectorAll('swiper-slide');

      const swiperEl = document.querySelector('swiper-container');

      swiperEl.swiper.on('reachEnd',()=>{
        swiperEl.swiper.allowSlideNext=false
      
        handleClose()
        
       // setOpenStory(false)
        
      })
      
      swiperEl.swiper.on('fromEdge', () => {
       
        swiperEl.swiper.allowSlideNext = true; 
        
      });

      const Slide = swiperEl.swiper.slides[picturendex];
      const firstVideo=Slide.childNodes[1].childNodes[0].childNodes[1].childNodes[0]
      if(Slide.childNodes[1].childNodes[0].childNodes[1].childNodes[0].dataset.type=="video/mp4"){
        
      firstVideo.play()
      //firstVideo.pause()
    
  
      firstVideo.onloadedmetadata = function() {
   
        clearInterval(TimeInterval)
        start=0
        time=0
        progress=0
        duration=Math.round(firstVideo.duration)
    
        //TimeLineStart()
        firstVideo.addEventListener('timeupdate',timeLineVideo)
        firstVideo.addEventListener('waiting',showLoader)
        firstVideo.addEventListener('canplay',hideLoader)
      }
     
    

     
      
       
      }
      else{
        hideLoader()
      clearInterval(TimeInterval)
      start=0
      time=0
      progress=0
      duration=15
     TimeLineStart()
      }
     
    
    
    swiperEl.swiper.on('slideChange',()=>{
      const activeSlide = swiperEl.swiper.slides[swiperEl.swiper.activeIndex];
      const activeSlideDataset = activeSlide.childNodes[1].childNodes[0].childNodes[1].childNodes[0].dataset.type
      const allVideos=activeSlide.querySelectorAll('.StoryVideo')

      if(activeSlideDataset!="image/jpeg"){
        
      allVideos.forEach(video=>{
        video.play()
        

        clearInterval(TimeInterval)
        start=0
        time=0
        progress=0
        duration=Math.round(video.duration)
       
        //TimeLineStart()
        video.addEventListener('timeupdate',timeLineVideo)
        video.addEventListener('waiting',showLoader)
        video.addEventListener('canplay',hideLoader)
     
      })


      const slidenumm=swiperEl.swiper.activeIndex
      swiperEl.swiper.slides.forEach((itemslide,index)=>{
        if(index!=slidenumm){
          const videosToPause = itemslide.querySelectorAll('.StoryVideo');
          videosToPause.forEach(video=>{
           
            video.pause()
            video.currentTime=0
            video.removeEventListener('timeupdate',timeLineVideo)
          })
        }
      })
    }
    else{
      const allVideos=document.querySelectorAll('.StoryVideo')
     
      allVideos.forEach(video=>{
        
        video.pause()
        video.currentTime=0
      })
      clearInterval(TimeInterval)
      start=0
      time=0
      progress=0
      duration=15
     TimeLineStart()
     
    }
    })
   

      storyScrollElement=document.getElementById("storyscreen")
      const allstory=document.querySelectorAll('.ParentDivDemo')
    
    
     
    
      storyWidth=document.getElementById("storyscreen").scrollWidth-document.getElementById("storyscreen").clientWidth
     // storyPicWidth=document.getElementById("image").getBoundingClientRect().width
      
      
       
    
      resolve()
    }
    else{
      reject()
    }
  })
}

function check(){

 

  checkElement()
  .then(()=>{
    document.getElementById("storyscreen").addEventListener('scroll', function(){
      swipe=false
      pauseTimeLine()
     
    })
  })
  .catch(()=>{
    check()
  })
}



  
useEffect(()=>{
  if(open){
    

    //TimeLineStart()
   
    check()

    
  
  }


ads.item.map((item,index)=>{
  imgAds.push(item.ad["url"])
 
})


adsvid.itemads.map((item,index)=>{
  vidAds.push(item.ad.url)

})


//video.src=vidAds[storyAd]

//pictAd.src=imgAds[storyAd]
//console.log(pictAd.src)


//document.documentElement.requestFullscreen()


},[open])


if(picturendex!=0){
 // picturendex++
}


 
 let translation=0

 /* 
 const swiperEl = document.querySelector('swiper-container');
      
      
 swiperEl.swiper.on('reachEnd',()=>{
   swiperEl.swiper.allowSlideNext=false
   console.log("END")
   handleClose()
   setOpenStory(false)
   
 })
 
 swiperEl.swiper.on('fromEdge', () => {
  
   swiperEl.swiper.allowSlideNext = true; 
   
 });
 */

  return (


  <>
     
    

      <Dialog
        fullScreen={true}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        sx={{background:'#000000', zIndex:2, overflowX:'scroll', whiteSpace:'nowrap', height:'100%', display:'flex', flexDirection:'column'}}
        
        className="Dialog"
      
      >




<div style={{height:'100%'}}>
<div className="TimeLine" id="timeline">
        <div id="timeremains" className='TimeRemains'>

        </div>
        </div>

        <Box className='StoryLoader' ref={circle_ref}>
                <CircularProgress style={{color:'#ffffff'}} size="4em"/>
                </Box>
<div className='StoryScreen' id="storyscreen">


<swiper-container slides-per-view="1" effect={plattform ? "none" : "cube"} grab-cursor="true" cube-effect-shadow="true"
    cube-effect-slide-shadows="true"  cube-effect-shadow-scale="0.94" initial-slide={picturendex} style={{height:'100%', width:'100%'}}>



<StoryItem/>

</swiper-container>


</div>

<div className='BlackDiv'></div>


</div>

      </Dialog>

    
      </>
     
    
  );




  function StoryItem(){


    
    return  Data.item.map((item, index)=>{
     
      if(index===parseInt(picturendex) && storyAdtype==="false"){
      return(
        <>
        <swiper-slide style={{height:'90vh'}}>
        <div className='story-clickTrigger-prev' onClick={prevSlide}></div>
     <div className='StoryIndex' key={index}>

      <div id="image" className='ParentDivDemo' >
      
      <AppBar sx={{ position: 'absolute', backgroundColor:'transparent', boxShadow:'0px 0px',top:'0'}}>
      

        <div className='StoryIcon'>
        {item.enclosure._attributes.type=="video/mp4" ?  <img className='StoryIconPic' id="storyiconpic" src={item.enclosure._attributes.icon}/>: <img className='StoryIconPic' id="storyiconpic" src={item.enclosure._attributes.url}/>}
       
        <div id="headText" className='incheck_head-text'>{item.enclosure._attributes.acc}</div>
        </div>
      <Toolbar>
          <IconButton
            edge="start"
          
            onClick={handleClose}
           
            aria-label="close"
            style={{right:0, position:'absolute', color:'white'}}
          >
            <CloseIcon />
          </IconButton>
         
        </Toolbar>
        
      </AppBar>
      
      <div id="storycontent" className='StoryContent'>
       
        
       
      
      {item.enclosure._attributes.type=="video/mp4" ? <video preload='auto' src={item.enclosure._attributes.url+"#t=0.001"} data-type={item.enclosure._attributes.type} className='StoryVideo' id={"storyvideoad"+index} playsInline loop></video> :  <img id={"storyimage"+index} data-type={item.enclosure._attributes.type} className='StoryImage' src={item.enclosure._attributes.url} />}


      

      
            
</div>



<div className='Message_Icon'>
<div className='Message'>


<p style={{lineHeight:0.1, position:'relative', left:'5%'}}>Nachricht senden</p>

</div>

<div>
<SendOutlinedIcon style={{ color:'white', transform:'rotate(333deg)'}}/>
</div>

<div>
<FavoriteBorderOutlinedIcon style={{color:'white'}}/>
</div>


</div>
</div>

</div>

<div className='story-clickTrigger-next' onClick={nextSlide}></div>
</swiper-slide>


{/*Ad */}
<swiper-slide style={{height:'90vh'}}>
<div className='story-clickTrigger-prev' onClick={prevSlide}></div>
     <div className='StoryIndex' key={index}>
<div className='ParentDivDemo'>
      
<AppBar sx={{ position: 'absolute', backgroundColor:'transparent', boxShadow:'0px 0px',top:'0'}}>
      

        <div className='StoryIcon'>
        <img className='StoryIconPic' id="storyiconpic" src={adsvid.itemads[storyAd].ad.acc_img}/>
        
        <div id="headText" className='incheck_head-text'>{adsvid.itemads[storyAd].ad.acc_name}</div>
        </div>
      <Toolbar>
          <IconButton
            edge="start"
          
            onClick={handleClose}
           
            aria-label="close"
            style={{right:0, position:'absolute', color:'white'}}
          >
            <CloseIcon />
          </IconButton>
         
        </Toolbar>
        
      </AppBar>
      
      <div id="storycontentVideo" className='StoryContent' >
       
      <div id="ad-layer" onTouchStart={handleVideoAdPause} onTouchEnd={handleVideoAdPlay}></div>

      <video preload='auto' src={adsvid.itemads[storyAd].ad.url+"#t=0.001"}  className='StoryVideo' id="storyvideoad" playsInline loop></video>
       
     
        
           
           
        
   
            
</div>


</div>

</div>
<div className='story-clickTrigger-next' onClick={nextSlide}></div>
</swiper-slide>
</>

)
      }


/////////////  Second ad


if(index===parseInt(picturendex)+2 && storyAdtype==="false" && adindex2!==null){
  return(
    <>
    <swiper-slide style={{height:'90vh'}}>
    <div className='story-clickTrigger-prev' onClick={prevSlide}></div>
 <div className='StoryIndex' key={index}>

  <div id="image" className='ParentDivDemo' >
  
  <AppBar sx={{ position: 'absolute', backgroundColor:'transparent', boxShadow:'0px 0px',top:'0'}}>
  

    <div className='StoryIcon'>
    {item.enclosure._attributes.type=="video/mp4" ?  <img className='StoryIconPic' id="storyiconpic" src={item.enclosure._attributes.icon}/>: <img className='StoryIconPic' id="storyiconpic" src={item.enclosure._attributes.url}/>}
   
    <div id="headText" className='incheck_head-text'>{item.enclosure._attributes.acc}</div>
    </div>
  <Toolbar>
      <IconButton
        edge="start"
      
        onClick={handleClose}
       
        aria-label="close"
        style={{right:0, position:'absolute', color:'white'}}
      >
        <CloseIcon />
      </IconButton>
     
    </Toolbar>
    
  </AppBar>
  
  <div id="storycontent" className='StoryContent'>
   
    
   
  
  {item.enclosure._attributes.type=="video/mp4" ? <video preload='auto' src={item.enclosure._attributes.url+"#t=0.001"} data-type={item.enclosure._attributes.type} className='StoryVideo' id={"storyvideoad"+index} playsInline loop></video> :  <img id={"storyimage"+index} data-type={item.enclosure._attributes.type} className='StoryImage' src={item.enclosure._attributes.url} />}


  

  
        
</div>


<div className='Message_Icon'>
<div className='Message'>


<p style={{lineHeight:0.1, position:'relative', left:'5%'}}>Nachricht senden</p>

</div>

<div>
<SendOutlinedIcon style={{ color:'white', transform:'rotate(333deg)'}}/>
</div>

<div>
<FavoriteBorderOutlinedIcon style={{color:'white'}}/>
</div>


</div>
</div>

</div>

<div className='story-clickTrigger-next' onClick={nextSlide}></div>
</swiper-slide>


{/*Ad */}
<swiper-slide style={{height:'90vh'}}>
<div className='story-clickTrigger-prev' onClick={prevSlide}></div>
 <div className='StoryIndex' key={index}>
<div className='ParentDivDemo'>
  
<AppBar sx={{ position: 'absolute', backgroundColor:'transparent', boxShadow:'0px 0px',top:'0'}}>
  

    <div className='StoryIcon'>
    <img className='StoryIconPic' id="storyiconpic" src={adsvid.itemads[adindex2].ad.acc_img}/>
    
    <div id="headText" className='incheck_head-text'>{adsvid.itemads[adindex2].ad.acc_name}</div>
    </div>
  <Toolbar>
      <IconButton
        edge="start"
      
        onClick={handleClose}
       
        aria-label="close"
        style={{right:0, position:'absolute', color:'white'}}
      >
        <CloseIcon />
      </IconButton>
     
    </Toolbar>
    
  </AppBar>
  
  <div id="storycontentVideo" className='StoryContent' >
   
  <div id="ad-layer" onTouchStart={handleVideoAdPause} onTouchEnd={handleVideoAdPlay}></div>

  <video preload='auto' src={adsvid.itemads[adindex2].ad.url+"#t=0.001"}  className='StoryVideo' id="storyvideoad" playsInline loop></video>
   
 
    
       
       
    

        
</div>


</div>

</div>
<div className='story-clickTrigger-next' onClick={nextSlide}></div>
</swiper-slide>
</>

)
  }




//////////////




      else if(index===parseInt(picturendex) && storyAdtype==="true"){

        return(

          <>
        <swiper-slide style={{height:'90vh'}}>
        <div className='story-clickTrigger-prev' onClick={prevSlide}></div>
        <div className='StoryIndex' key={index} >

      <div id="image" className='ParentDivDemo' >
      
      <AppBar sx={{ position: 'absolute', backgroundColor:'transparent', boxShadow:'0px 0px',top:'0'}}>
      

        <div className='StoryIcon'>
        {item.enclosure._attributes.type=="video/mp4" ?  <img className='StoryIconPic' id="storyiconpic" src={item.enclosure._attributes.icon}/>: <img className='StoryIconPic' id="storyiconpic" src={item.enclosure._attributes.url}/>}
        
        <div id="headText" className='incheck_head-text'>{item.enclosure._attributes.acc}</div>
        </div>
      <Toolbar>
          <IconButton
            edge="start"
          
            onClick={handleClose}
           
            aria-label="close"
            style={{right:0, position:'absolute', color:'white'}}
          >
            <CloseIcon />
          </IconButton>
         
        </Toolbar>
        
      </AppBar>
      
      <div id="storycontent" className='StoryContent'>
       
      
       
      
      {item.enclosure._attributes.type=="video/mp4" ? <video preload='auto' src={item.enclosure._attributes.url+"#t=0.001"} data-type={item.enclosure._attributes.type} className='StoryVideo' id={"storyvideoad"+index} playsInline loop></video> :  <img id={"storyimage"+index} data-type={item.enclosure._attributes.type} className='StoryImage' src={item.enclosure._attributes.url} />}

      

      
            
</div>



<div className='Message_Icon'>
<div className='Message'>


<p style={{lineHeight:0.1, position:'relative', left:'5%'}}>Nachricht senden</p>

</div>

<div>
<SendOutlinedIcon style={{ color:'white', transform:'rotate(333deg)'}}/>
</div>

<div>
<FavoriteBorderOutlinedIcon style={{color:'white'}}/>
</div>


</div>

</div>

</div>
<div className='story-clickTrigger-next' onClick={nextSlide}></div>
</swiper-slide>


<swiper-slide style={{height:'90vh'}}>
<div className='story-clickTrigger-prev' onClick={prevSlide}></div>
<div className='StoryIndex' key={index} >
<div className='ParentDivDemo'>
      
<AppBar sx={{ position: 'absolute', backgroundColor:'transparent', boxShadow:'0px 0px',top:'30px'}}>
      

        <div className='StoryIcon'>
        <img className='StoryIconPic' id="storyiconpic" src={imgAds[storyAd]}/>
       
        <div id="headText" className='incheck_head-text'>Anzeige</div>
        </div>
      <Toolbar>
          <IconButton
            edge="start"
          
            onClick={handleClose}
           
            aria-label="close"
            style={{right:0, position:'absolute', color:'white'}}
          >
            <CloseIcon />
          </IconButton>
         
        </Toolbar>
        
      </AppBar>
      
      <div id="storycontent" className='StoryContent'>
       
       
       
     <img src={imgAds[storyAd]} className='StoryImage' id="storyimage" />
        
           
           
        
   
            
</div>

</div>
</div>
<div className='story-clickTrigger-next' onClick={nextSlide}></div>
</swiper-slide>
</>

)


      }else{

        return(
   
<swiper-slide style={{height:'90vh'}}>
<div className='story-clickTrigger-prev' onClick={prevSlide}></div>
<div className='StoryIndex' key={index}>
<div  className='ParentDivDemo'>
      
<AppBar sx={{ position: 'absolute', backgroundColor:'transparent', boxShadow:'0px 0px',top:'0'}}>
      

        <div className='StoryIcon'>
        {item.enclosure._attributes.type=="video/mp4" ?  <img className='StoryIconPic' id="storyiconpic" src={item.enclosure._attributes.icon}/>: <img className='StoryIconPic' id="storyiconpic" src={item.enclosure._attributes.url}/>}
        
        <div id="headText" className='incheck_head-text'>{item.enclosure._attributes.acc}</div>
        </div>
      <Toolbar>
          <IconButton
            edge="start"
          
            onClick={handleClose}
           
            aria-label="close"
            style={{right:0, position:'absolute', color:'white'}}
          >
            <CloseIcon />
          </IconButton>
         
        </Toolbar>
        
      </AppBar>
      
      <div id="storycontent" className='StoryContent'>
       
    
   
      
      {item.enclosure._attributes.type=="video/mp4" ? <video preload='auto' src={item.enclosure._attributes.url+"#t=0.001"} data-type={item.enclosure._attributes.type} className='StoryVideo' id={"storyvideoad"+index} playsInline loop></video> :  <img id={"storyimage"+index} data-type={item.enclosure._attributes.type} className='StoryImage' src={item.enclosure._attributes.url} />}

      

      
            
</div>


<div className='Message_Icon'>
<div className='Message'>


<p style={{lineHeight:0.1, position:'relative', left:'5%'}}>Nachricht senden</p>

</div>

<div>
<SendOutlinedIcon style={{ color:'white', transform:'rotate(333deg)'}}/>
</div>

<div>
<FavoriteBorderOutlinedIcon style={{color:'white'}}/>
</div>


</div>

</div>
</div>
<div className='story-clickTrigger-next' onClick={nextSlide}></div>
</swiper-slide>


)

      }


    })
  
  }

 

}


export default Story_Content
